@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.conclusion {
  background: $color-white;
  border-radius: $border-radius-m;
  font-size: var(--font-size-xxl);
  font-weight: 700;
  padding: $baseline * 0.75 $baseline $baseline * 0.75 $baseline * 3.25;
  margin: $baseline $baseline * 1.5 $baseline * 2;
  border-left-width: $baseline * 0.5;
  border-left-style: solid;
  background-repeat: no-repeat;
  background-position: $baseline 50%;

  &.advice {
    border-left-color: #ffb800;
    background-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMiIgZmlsbD0iI0ZGQjgwMCIgLz4KICAgIDxwYXRoCiAgICAgIGQ9Ik0xMiA0LjVjLS44MjggMC0xLjUuNjc1LTEuNSAxLjUwOHY4LjA5NWMwIC44MzIuNjcyIDEuNTA3IDEuNSAxLjUwN3MxLjUtLjY3NSAxLjUtMS41MDhWNi4wMDhjMC0uODMzLS42NzItMS41MDgtMS41LTEuNTA4Wk0xMiAxOS41Yy44MjggMCAxLjUtLjY0OSAxLjUtMS40NDlzLS42NzItMS40NDktMS41LTEuNDQ5LTEuNS42NDktMS41IDEuNDVjMCAuOC42NzIgMS40NDggMS41IDEuNDQ4WiIKICAgICAgZmlsbD0iI2ZmZiIKICAgIC8+CiAgPC9zdmc+');
  }

  &.normal {
    border-left-color: #37a489;
    background-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aAogICAgICBkPSJNOS4xOSAxOC4yNzZhMS4yMiAxLjIyIDAgMCAxLTEuNzMzIDBMLjU0IDExLjM1NmExLjgzOCAxLjgzOCAwIDAgMSAwLTIuNTk5bC44NjYtLjg2NWExLjgzNyAxLjgzNyAwIDAgMSAyLjYgMGw0LjMxOSA0LjMyTDE5Ljk5Ni41MzlhMS44MzcgMS44MzcgMCAwIDEgMi42IDBsLjg2Ni44NjdhMS44MzYgMS44MzYgMCAwIDEgMCAyLjU5OEw5LjE5IDE4LjI3NloiCiAgICAgIGZpbGw9IiMzN0E0ODkiCiAgICAvPgogIDwvc3ZnPg==');
  }

  &.alert {
    border-left-color: #f4616b;
    background-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiNGNDYxNkIiLz48cGF0aCBkPSJNMTIgNC41Yy0uODI4IDAtMS41LjY3NS0xLjUgMS41MDh2OC4wOTVjMCAuODMyLjY3MiAxLjUwNyAxLjUgMS41MDdzMS41LS42NzUgMS41LTEuNTA4VjYuMDA4YzAtLjgzMy0uNjcyLTEuNTA4LTEuNS0xLjUwOFpNMTIgMTkuNWMuODI4IDAgMS41LS42NDkgMS41LTEuNDQ5cy0uNjcyLTEuNDQ5LTEuNS0xLjQ0OS0xLjUuNjQ5LTEuNSAxLjQ1YzAgLjguNjcyIDEuNDQ4IDEuNSAxLjQ0OFoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=');
  }
}
