@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.homepage {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;

  @media screen and (max-width: $tablet) {
    background-color: $color-header-primary-bg;
  }

  @media screen and (min-width: $tablet) {
    // override any inline styles added on Dashboard
    // for images that need translucent background
    background-color: $color-primary-bg !important;
  }

  .pageLogoWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: $baseline * 2;
    margin-bottom: $baseline * 4;
  }

  .pageHeaderWrapper {
    display: none;
  }

  .pageWrapper {
    padding-top: 0;
  }

  h2,
  h3 {
    color: $color-white;
  }

  .generalBannerWrapper {
    align-items: center;
    padding: $baseline * 0.5;
    color: $color-white;

    .columnText {
      flex-direction: column;
    }

    .generalBannerIcon {
      path {
        fill: $color-white;
      }
    }
  }

  .fillProfileBannerWrapper {
    background-color: $color-header-primary-bg;
  }

  .resendEmailBannerWrapper {
    background-color: $color-warning;
  }
}

@media screen and (min-width: $tablet) {
  .homepage {
    background-image: none !important;

    h2,
    h3 {
      color: $text-color-primary-bg;
    }

    .pageHeaderWrapper {
      display: block;
    }

    .pageLogoWrapper {
      display: none;
    }

    .pageWrapper {
      padding-top: 100px;
    }
  }
}
