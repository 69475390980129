@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.fieldset {
  border: none;
}

.legend {
  font-weight: 700;
  font-size: var(--font-size-xl);
  color: $text-color-primary-bg;
  padding-bottom: $baseline * 1.25;
}

.dateInputKnownWrapper {
  display: flex;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }

  input {
    height: 40px;
    width: 50px;
    margin-right: math.div($baseline, 2);
    border: $border-width-s solid #e7e7e7;
    padding: 0 0 0 math.div($baseline, 1.6); // 10px
    border-radius: 0;
  }

  .inputYear {
    width: 60px;
    margin-right: 0;
  }

  &.hasError {
    input {
      border-color: var(--text-validation-error);
    }
  }

  .error {
    margin: math.div($baseline, 2) 0 0 math.div($baseline, 2);
  }
}
