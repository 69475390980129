@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.upcomingBookings {
  margin-top: $baseline * 2;
  padding-left: 25px;
  padding-right: 25px;
}

.upcomingBookingWrapper {
  position: relative;
  margin: 0 0 $baseline;
  padding: $baseline $baseline $baseline $baseline + 10px;
  border-top-right-radius: $baseline * 0.5;
  border-bottom-right-radius: $baseline * 0.5;
  border: 1px solid var(--card-border-color);
  background-color: $color-white;
  display: grid;
  grid-template-columns: 53px 1fr;
  grid-template-areas:
    'icon info'
    'actions actions';

  @media screen and (min-width: 600px) {
    grid-template-columns: 53px 1fr 240px;
    grid-template-areas:
      'icon info actions'
      '. info actions';
  }

  &::before {
    content: '';
    width: 10px;
    background: $color-icon;
    position: absolute;
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
  }

  .upcomingBookingIcon {
    grid-area: icon;

    div {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $color-icon;

      svg {
        margin-top: 2px;
      }
    }
  }

  .upcomingBookingInfo {
    grid-area: info;

    h3 {
      font-size: var(--font-size-xl);
      font-weight: bold;
      line-height: 1.1;
      margin-bottom: $baseline * 0.25;
      color: $text-color-primary-bg;
      padding-left: 0;
    }

    .patientName {
      display: inline-block;
      margin-top: $baseline * 0.25;
      background: var(--color-azure-10);
      color: $text-color-secondary-bg;
      font-size: var(--font-size-m);
      padding: $baseline * 0.25 $baseline * 0.5;
      border-radius: $border-radius-s;
    }

    .meta {
      margin-top: 20px;
      font-weight: 700;

      .metaDate {
        display: flex;
        flex-direction: row;

        p {
          display: inline-block;

          &:first-child {
            margin-right: $baseline * 0.5;
            padding-right: $baseline * 0.5;
            border-right: 2px solid $text-color-primary-bg;
          }
        }
      }

      .metaAddToCalendar {
        margin-top: $baseline * 0.5;
      }
    }
  }

  .upcomingBookingActions {
    grid-area: actions;
    margin-top: $baseline;

    @media screen and (min-width: 600px) {
      margin-top: 0;
    }
  }
}

.phoneCall {
  margin: 20px 0 0 $baseline * 4;

  @media screen and (min-width: 600px) {
    margin: 20px 0 0 0;
  }
}
