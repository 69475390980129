@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.conclusions {
  padding-left: $baseline;

  li {
    list-style: disc;
    line-height: 1.333;
    margin-bottom: $baseline * 0.25;
  }
}