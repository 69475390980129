@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.accordion {
  margin-bottom: $baseline * 0.5;
  border: $border-width-s solid #6e8ec4;
  background: $color-white;
  padding: $baseline;
  border-radius: $border-radius-m;

  summary {
    padding: $baseline;
    cursor: pointer;
    margin: -$baseline;
    font-size: var(--font-size-l);
    font-weight: 500;
    list-style: none;
    list-style-image: none;
    background-repeat: no-repeat;
    background-position: right $baseline center;
    background-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE1LjA2IDMuMDZBMS41IDEuNSAwIDAgMCAxMi45NC45NGwyLjEyIDIuMTJaTTggOCA2Ljk0IDkuMDZhMS41IDEuNSAwIDAgMCAyLjEyIDBMOCA4Wk0zLjA2Ljk0QTEuNSAxLjUgMCAwIDAgLjk0IDMuMDZMMy4wNi45NFptOS44OCAwLTYgNiAyLjEyIDIuMTIgNi02TDEyLjk0Ljk0Wm0tMy44OCA2LTYtNkwuOTQgMy4wNmw2IDYgMi4xMi0yLjEyWiIgZmlsbD0iIzI0MkQzOCIvPjwvc3ZnPg=='); // chevron down
    border-top-left-radius: $border-radius-m - 1px; // -1 to match background to border radious
    border-top-right-radius: $border-radius-m - 1px;

    &::marker {
      display: none;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] > summary {
    background: #475a79;
    border-color: #475a79;
    color: $color-white;
    margin-top: -$baseline;
    margin-left: -$baseline;
    margin-right: -$baseline;
    margin-bottom: $baseline;
    list-style: none;
    list-style-image: none;
    background-repeat: no-repeat;
    background-position: right $baseline center;
    background-image: url('data:image/svg+xml; base64, PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTS45NCA2Ljk0YTEuNSAxLjUgMCAxIDAgMi4xMiAyLjEyTC45NCA2Ljk0Wk04IDIgOS4wNi45NGExLjUgMS41IDAgMCAwLTIuMTIgMEw4IDJabTQuOTQgNy4wNmExLjUgMS41IDAgMCAwIDIuMTItMi4xMmwtMi4xMiAyLjEyWm0tOS44OCAwIDYtNkw2Ljk0Ljk0bC02IDYgMi4xMiAyLjEyWm0zLjg4LTYgNiA2IDIuMTItMi4xMi02LTYtMi4xMiAyLjEyWiIgZmlsbD0iI2ZmZmZmZiIvPjwvc3ZnPg=='); // chevron up
  }

  &.alert {
    & > summary svg {
      vertical-align: bottom;
    }
  }

  &.advice {
    border: $border-width-s solid #fdcb4e;
    background-color: #fdcb4e;
  }

  .content {
    font-weight: 300;
    font-size: var(--font-size-l);

    ul {
      margin: 0 $baseline $baseline * 0.5 $baseline * 2;
    }

    li {
      list-style-type: disc;
      line-height: 1.33333;

      &[data-title='true'] {
        list-style: none;
        margin-left: -$baseline * 1.25;
        margin-bottom: $baseline * 0.5;
        font-weight: 700;
      }
    }
  }
}
