@use 'sass:math';
@import '../../../styles/ui/breakpoints.scss';
@import '../../../styles/ui/colors.scss';
@import '../../../styles/ui/spacing.scss';
@import '../../../styles/ui/typography.scss';

@import '../../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.container {
    padding-top: $baseline - 2;
    padding-bottom: $baseline - 2;
    cursor: pointer;
}

.checkbox {
    opacity: 0; // hides default browser checkbox
    position: absolute;

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }
  
    & + label:before {
        content: '';
        margin-right: 10px;
        display: flex;
        vertical-align: text-top;
        width: 14px;
        min-width: 14px;
        height: 14px;
        background: $color-white;
        border-radius: $border-radius-m;
        border: $border-width-s solid #242D38;
    }

}
    
.checkbox:checked {
    & ~ label:after {
        content: '';
        position: absolute;
        left: 3px;
        top: 7px;
        background: $color-primary;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $color-primary, 4px 0 0 $color-primary,
            4px -2px 0 $color-primary, 4px -4px 0 $color-primary,
            4px -6px 0 $color-primary, 4px -8px 0 $color-primary;
        transform: rotate(45deg);
    }
}

