@use 'sass:math';
@import '../../styles/ui/breakpoints.scss';
@import '../../styles/ui/colors.scss';
@import '../../styles/ui/spacing.scss';
@import '../../styles/ui/typography.scss';

@import '../../styles/ui/components/app-footer.scss';

// spacing
$baseline: 16px;

// borders
$border-radius-l: 12px;
$border-radius-m: $baseline * 0.5;
$border-radius-s: $baseline * 0.25;

$border-width-s: 1px;

// colors
$color-primary: #f4616b;
$color-white: #fff;
$color-black: #000;
$color-primary-bg: #f6f6f6;
$color-secondary-bg: #e1e5ee;
$color-header-primary-bg: #475a79;
$color-banner-blue: #4e74b7;

$color-success: #55a266;
$color-warning: #f4616b;
$color-icon: linear-gradient(180deg, #f4616b 0%, #475a79 75.13%);
/// text on background
$text-color-secondary-bg: #676e78;

/// text on primary color
$text-color-primary-bg: #242d38;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  margin: 0 0 var(--spacing-3);
  font-weight: bold;
}

.button {
  user-select: none;
  border: none;
  background: var(--color-white);
  border: 1px solid var(--color-white);
  padding: 0 10px;
  border-radius: $border-radius-m;
  font-size: 24px;
  line-height: 46px;
  width: 62px;
  cursor: pointer;
  box-shadow: 2px 4px 16px -4px rgba(36, 45, 56, 0.08);
  transition: 0.2s ease all;

  &:hover {
    border: 1px solid #ccc;
  }

  &:active {
    background: #eee;
  }

  &:disabled {
    background: var(--color-grey-10);
    border-color: var(--color-grey-10);
    box-shadow: none;
    cursor: default;
  }
}

.grid {
  width: 100%;
  background-color: var(--color-white);
  border-radius: $border-radius-m;
  padding: 4px;

  th {
    color: #444;
    text-align: center;
    padding: $baseline * 0.5 0;
    font-weight: 300;
  }
}

.cell {
  cursor: default;
  text-align: center;
  font-weight: bold;
  padding: $baseline 0;
  position: relative;
  outline: none;
  color: #333;

  &:hover {
    background: #eee;
    cursor: pointer;
  }

  &.selected {
    background: var(--color-grey-30);
  }

  &.focusRing {
    background: var(--color-coral-20);
  }

  &.unavailable {
    text-decoration: line-through;

    &::before,
    &::after {
      content: '\00a0 ';
    }

    &:hover {
      background: inherit;
      cursor: default;
    }
  }

  &.disabled,
  &.disabled.unavailable {
    background-color: var(--color-grey-10);
    color: var(--color-grey-40);
    cursor: default;
    text-decoration: none;
  }
}
